// Open.fm API station numbers:
// https://open.fm/api/radio/stations

const rdsFetchers = {
	radiowroclaw: async () => {
		const url = 'https://api.radiograj.pl/rds/radiowroclaw';
		const result = await (await fetch(url)).json();

		const rds = {
			message: result.message,
		};

		return rds;
	},

	radiokrakow: async () => {
		const url = 'https://api.radiograj.pl/rds/radiokrakow';
		const result = await (await fetch(url)).json();

		const rds = {
			message: result.message,
		};

		return rds;
	},

	radiopogoda: async () => {
		return loadFromAPI(`/rds/openfm?station=68`);
	},

	rmfclassic: async () => {
		return loadFromAPI(`/rds/openfm?station=173`);

		const url = 'https://www.rmfon.pl/stacje/playlista_7.json.txt';
		const result = await (await fetch(url)).json();
		console.log('rmfca', result);
		const current = result.find((item) => item.order === 1) || {};
		const rds = {
			message: `${current.author}${current.title ? `: ${current.title}` : ''}`,
			image: current.coverBigUrl,
		};

		return rds;
	},

	rmfrock: async () => {
		const url = 'https://www.rmfon.pl/stacje/playlista_2.json.txt';
		const result = await (await fetch(url)).json();

		const current = result.find((item) => item.order === 0);
		const rds = {
			message: `${current.author}${current.title ? `: ${current.title}` : ''}`,
			image: current.coverBigUrl,
		};

		return rds;
	},

	rmfswieta: async () => {
		const url = 'https://www.rmfon.pl/stacje/playlista_17.json.txt';
		const result = await (await fetch(url)).json();

		const current = result.find((item) => item.order === 0);
		const rds = {
			message: `${current.author}${current.title ? `: ${current.title}` : ''}`,
			image: current.coverBigUrl,
		};

		return rds;
	},

	rmfkoledy: async () => {
		const url = 'https://www.rmfon.pl/stacje/playlista_80.json.txt';
		const result = await (await fetch(url)).json();

		const current = result.find((item) => item.order === 0);
		const rds = {
			message: `${current.author}${current.title ? `: ${current.title}` : ''}`,
			image: current.coverBigUrl,
		};

		return rds;
	},

	nowyswiat: async () => {
		return loadFromAPI(`/rds/openfm?station=62`);
	},

	antyradio: async () => {
		const url = 'https://api.radiograj.pl/rds/antyradio';
		const result = await (await fetch(url)).json();

		const rds = {
			message: result.message,
		};

		return rds;
	},

	radio357: async () => {
		return loadFromAPI(`/rds/openfm?station=61`);
	},
	nagwiazdke: async () => {
		return loadFromAPI(`/rds/openfm?station=na-gwiazdke`);
	},
	nagwiazdkerock: async () => loadFromAPI(`/rds/openfm?station=na-gwiazdke-rock`),
	nagwiazdkejazz: async () => loadFromAPI(`/rds/openfm?station=na-gwiazdke-jazz`),
	nagwiazdkehity: async () => loadFromAPI(`/rds/openfm?station=na-gwiazdke-hity`),
	nagwiazdkekoledy: async () => loadFromAPI(`/rds/openfm?station=na-gwiazdke-koledy`),
	// odglosyNatury: async () => loadFromAPI(`/rds/openfm?station=82`),

	// trojka: async () => {
	//   const url = `https://api.radiograj.pl/rds/trojka`
	//   const result = await (await fetch(url)).json()

	//   return {
	//     message: result.message,
	//   }
	// },
};

async function loadFromAPI(route) {
	// const url = `http://localhost:7777${route}`;
	const url = `https://api.radiograj.pl${route}`;
	const result = await (await fetch(url)).json();

	const rds = {
		message: result.message,
	};

	return rds;
}

async function getRDS(station) {
	if (!rdsFetchers[station]) {
		return {};
	}
	return rdsFetchers[station]();
}

export default getRDS;
