export const programmeFetchers = {
	nowyswiat: async () => {
		// const url = 'http://localhost:7777/programme/nowyswiat';
		const url = 'https://api.radiograj.pl/programme/nowyswiat';
		const result = await (await fetch(url)).json();

		return result;
	},
	radio357: async () => {
		// const url = 'http://localhost:7777/programme/radio357';
		const url = 'https://api.radiograj.pl/programme/radio357';
		const result = await (await fetch(url)).json();

		return result;
	},
};

export function hasProgramme(station) {
	return !!programmeFetchers[station];
}

async function getProgramme(station) {
	if (!programmeFetchers[station]) {
		return null;
	}

	return programmeFetchers[station]();
}

export default getProgramme;
